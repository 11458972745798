<script lang="ts" setup>
const {
	public: { baseImageUrl }
} = useRuntimeConfig();

withDefaults(
	defineProps<{
		type: "link" | "button";
		title?: string;
		link: string;
		iconHtml?: string;
		iconLink?: string;
		active?: boolean;
		dataTid?: string;
	}>(),
	{ type: "link" }
);

const emit = defineEmits<{ (event: "clickTab", link: string): void }>();

const isSvgCode = (icon: string): boolean => icon.trim().startsWith("<svg");
const isSvgPath = (icon: string): boolean => icon.trim().endsWith(".svg");
</script>

<template>
	<template v-if="type === 'button'">
		<div :class="['tab', { 'tab-active': active }]" :data-tid="dataTid" @click="emit('clickTab', link)">
			<NuxtIcon v-if="iconLink" :name="iconLink" filled class="icon" />
			<i v-else-if="iconHtml && isSvgCode(iconHtml)" class="icon" v-html="iconHtml" />
			<NuxtImg
				v-else-if="iconHtml && isSvgPath(iconHtml)"
				:src="`${baseImageUrl}${iconHtml}`"
				width="40"
				height="40"
				loading="lazy"
				alt="category"
				class="icon icon-img"
			/>
			<AText variant="talca" class="title">{{ title }}</AText>
		</div>
	</template>
	<template v-else>
		<NuxtLink :to="link" :class="['tab', { 'tab-active': active }]" :data-tid="dataTid">
			<NuxtIcon v-if="iconLink" :name="iconLink" filled class="icon" />
			<i v-else-if="iconHtml && isSvgCode(iconHtml)" class="icon" v-html="iconHtml" />
			<NuxtImg
				v-else-if="iconHtml && isSvgPath(iconHtml)"
				:src="`${baseImageUrl}${iconHtml}`"
				width="40"
				height="40"
				loading="lazy"
				alt="category"
				class="icon"
			/>
			<AText variant="talca" class="title">{{ title }}</AText>
		</NuxtLink>
	</template>
</template>

<style lang="scss" scoped>
.tab {
	display: inline-flex;
	flex-direction: column;
	flex: 1;
	gap: 2px;
	align-items: center;
	justify-content: flex-end;
	height: 62px;
	min-width: 130px;
	padding: 8px 4px;
	border-radius: 8px;
	background: var(--neutral-15);
	flex-shrink: 0;
	margin: 16px 0 10px;
	cursor: pointer;

	@include media-breakpoint-down(md) {
		min-width: 94px;
		height: 60px;
		margin: 12px 0 2px;
		padding: 8px 2px;
	}

	.title {
		color: var(--neutral-70);
	}

	.icon {
		will-change: transform;
		transition: all 150ms ease-in-out;
		display: inline-flex;
		width: auto;
		height: 40px;
		font-size: 40px;

		:deep(svg) {
			width: 100%;
			height: 100%;
		}

		&-img {
			width: 40px;
			height: auto;
			object-fit: cover;
		}
	}
	&-active {
		background: var(--neutral-30);
		pointer-events: none;

		.title {
			color: var(--tertiary-80);
		}

		.icon {
			transform: scale(1.3);
		}
	}
}
</style>
